export const BSC = 56
export const BSC_TESTNET = 97
export const ARBITRUM = 42161
export const ARBITRUM_TESTNET = 421614
export const AVALANCHE = 43114

export const addresses = {
    [BSC]: {
        GMX: '0x29745314B4D294B7C77cDB411B8AAa95923aae38',
        BNB: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        RewardReader: '0x126411078f878743AcA4eAA1698716d5BC402597',
        GLP: '0x8b47515579c39a31871D874a23Fb87517b975eCC',
        GlpManager: '0x6876B9804719d8D9F5AEb6ad1322270458fA99E0'
    },
    [BSC_TESTNET]: {
        GMX: '0x29745314B4D294B7C77cDB411B8AAa95923aae38',
        BNB: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
        BTC: '0x7e0bBC97333458dD27048753336478DeDd0bB11d',
        ETH: '0x1FEc323e5eD2D39d6bfC8E1F87dF0f8E054C9bAA',
        RewardReader: '0x41BFbCA563875C09b77CFA341F6D8f6d7138cE41',
        GLP: '0x4eD6B5B5a4d68e6C5bEe27f4E117693afBE26707',
        GlpManager: '0xE76035674F2D9De0Ad7Af9eA43a3946cA7e08d04'
    },

    [ARBITRUM]: {
        GMX: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        BTC: '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
        ETH: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        RewardReader: '0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0',
        GLP: '0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258',
        GlpManager: '0x321F653eED006AD1C29D174e17d96351BDe22649'
    },

    [ARBITRUM_TESTNET]: {
        GMX: '0xa399411d6b43904Eb499BAAb30259224237Be697',
        BTC: '0xDa751873cd11cCf2EE23651425511f3bf7B3cfeA',
        ETH: '0xDD47235189d5FB61A16daEd5Bcb12A65B683E395',
        // LINK: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
        // UNI: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
        // DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        RewardReader: '0xfdC06D05f34943c452E46996F682d8Ab612E0A0b',
        GLP: '0x7AEAc2Cfd6c404d1f24D839A29F2a6373B576538',
        GlpManager: '0xc644AE591a93815B7C4dDB536bB4D1a4857cAc07'
    },

    [AVALANCHE]: {
        GMX: '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
        AVAX: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        ETH: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
        BTC: '0x50b7545627a5162f82a992c33b87adc75187b218',
        RewardReader: '0x04Fc11Bd28763872d143637a7c768bD96E44c1b6',
        GLP: '0x01234181085565ed162a948b6a5e88758CD7c7b8',
        GlpManager: '0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F'
    }
}

export const arbitrumTokenSymbols = {
    '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': 'BTC',
    '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': 'ETH',
}

export function getAddress(chainId, key) {
    if (!(chainId in addresses)) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
